import * as Sentry from "@sentry/astro";

Sentry.init({
  dsn: "https://2e048b4fee4dfd4cc6b0eeeffa89f428@o4507711088558080.ingest.de.sentry.io/4507711099437136",
  debug: false,
  environment: import.meta.env.PUBLIC_VERCEL_ENV,
  release: import.meta.env.PUBLIC_VERCEL_GIT_COMMIT_SHA,
  tracesSampleRate: 1,
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1,
});